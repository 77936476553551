import React from 'react';
import pic from '../Components/The Sociologist.png';
import { useNavigate } from 'react-router-dom';


function Nav() {
  const navigate = useNavigate()
  return (
    <div className="mb-4 border-b border-black-900 fixed top-0 w-full flex items-center  justify-between py-6 bg-white">      
      <div className="flex lg:w-1/3 items-center md:mx-28">
        <img onClick={(()=>{
          navigate('/')
        })} src='' alt="Logo" className="h-10 w-auto cursor-pointer " />
      </div>

      <div className="hidden lg:flex mx-8 my-4 lg:w-2/3 justify-center items-center cursor-pointer text-black text-xl font-normal gap-12">
        <p onClick={(()=>{
          navigate('/')
        })}>Home</p>
        <p onClick={(()=>{
          navigate('/about');
        })}>About us</p>
        <p onClick={(()=>{
          navigate('/courses')
        })}>Courses</p>
        <p>Blog</p>
        <p>Contact</p>
      </div>
    </div>
  );
}

export default Nav;
