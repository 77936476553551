import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";


function Footer() {
  return (
    <div className='flex flex-row h-3/4 w-full items-center justify-start px-3 border-b border-black-900 bg-white text-black'>
        <div className='flex w-1/4 '>
            <div className=''>
            <FaFacebook className='size-10'/>
            <FaInstagram className='size-10'/>
            <FaYoutube className='size-10'/>

            </div>
        </div>

      
    </div>
  )
}

export default Footer
