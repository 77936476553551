import React from 'react'
import Nav from '../Components/Nav'
import CourseMain from '../Components/CourseMain'

function Courses() {
  return (
    <div>
    <Nav/>
    <CourseMain/>
      
    </div>
  )
}

export default Courses
