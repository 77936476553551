import React from 'react'
import {course_tag,COURSES} from '../Constants/Constants'

function CourseMain() {
  return (
    <div className='flex h-full w-full items-start  justify-center border-b border-black-900 bg-white text-black pt-44 md:t-64'>

    <div className='flex flex-col text-center'>
        <h3 className='text-xl pb-12'>Our Courses</h3>
        <h2 className='text-4xl pb-6'>Learn from the best MENTORS</h2>
        <h1 className='text-md md:text-xl text-center font-light pb-6'>{course_tag}</h1>

        <div className=' flex flex-col md:px-20'>
        <div className='flex flex-col lg:flex-row lg:flex-wrap lg:justify-evenly gap-10'>
          {COURSES.map((course, index) => (
            <div key={index} className='p-4 border my-4 w-full lg:w-1/3 bg-white rounded-lg shadow-lg '>
              <img src={course.image} alt={course.name} className='w-full h-38 object-cover mb-4 rounded-lg' />
              <h2 className='text-2xl font-bold mb-2'>{course.name}</h2>
              <p className='text-sm font-light'>{course.description}</p>
              <button className='border-2 rounded-lg px-2 py-1 bg-[#468DE0] text-white text-xl ' >Join Course</button>
            </div>
          ))}
        </div>
        </div>
        </div>
    </div>
  )
}

export default CourseMain
