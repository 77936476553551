import React from 'react'
import { tagline, smtag } from '../Constants/Constants'
import { useNavigate } from 'react-router-dom';

function Homeh() {

  
  return (
    <div className='flex h-screen w-full items-center justify-center border-b border-black-900 bg-white text-black'>
      <div className='text-center'>
        <h1 className='text-6xl md:text-8xl'>{tagline}</h1>
        <h6 className='mt-4 text-lg'>{smtag}</h6>
      </div>
    </div>
  )
}

export default Homeh
