import React from 'react'
import Nav from '../Components/Nav'
import Homeh from '../Components/Homeh'
import Course from '../Components/Course'
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';

function Home() {

  const navigate = useNavigate()
  return (
    <div className='flex flex-col items-center'> 
      <Nav />
      <Homeh />
      <Course/>
      <Footer/>
    </div>
  )
}

export default Home
