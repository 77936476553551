import React from 'react';
import { hmcourse1, hmcourse2 } from '../Constants/Constants';
import { COURSES } from '../Constants/Constants';
import { useNavigate } from 'react-router-dom';


function Course() {
  const navigate = useNavigate(); 

  return (
    <div className='h-full w-full flex flex-col lg:flex-row items-center justify-center border-b border-black-900 bg-white text-black py-10 overflow-y-hidden'>
      
      <div className='lg:w-1/3 flex flex-col mx-8 text-start pb-10'> 
        <h6 className='text-xl mb-4'>Our Courses</h6> 
        <h1 className='text-3xl mb-4 font-bold'>{hmcourse1}</h1>
        <h6 className='text-xl mb-4'>{hmcourse2}</h6>
        <div className=''>

        <button className='border-2 bg-blue-500 text-white rounded-3xl py-4 px-6 mx-4' onClick={() => {
          navigate('/courses'); 
        }}>Explore Course</button>
        </div>
      </div>

      <div className='lg:w-3/4 flex flex-col'>
        <div className='flex flex-col lg:flex-row lg:flex-wrap lg:justify-evenly gap-10'>
          {COURSES.map((course, index) => (
            <div key={index} className='p-4 border my-4 w-full lg:w-1/3 bg-white rounded-lg shadow-lg '>
              <img src={course.image} alt={course.name} className='w-full h-38 object-cover mb-4 rounded-lg' />
              <h2 className='text-2xl font-bold mb-2'>{course.name}</h2>
              <p className='text-sm font-light'>{course.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Course; // Component is exported properly
