import React from 'react'
import Nav from '../Components/Nav'

function About() {
  return (
    <div>
        <Nav/>
        <About/>
      
    </div>
  )
}

export default About
